<template>
    <fullEditorsEditor @setDataInItem="input($event)" :content="item.Placeholder" :disabled="true"  :applicantMode="true"/>
</template>

<script>
import fullEditorsEditor from '@/components/templateFormParts/fullEditorsEditor'


export default {
    name: 'fullEditor',
    props: ['item', 'Id', 'readOnly', 'preview'],
    emits: [''],
    components: {
        fullEditorsEditor
    },
    data() {
        return {
        }
    },
    watch: {
    },
    mounted() {
    },

    beforeUnmount() {
    },

    computed: {
    },
    methods: {
        input: function(e={}) {
        },
    },
    directives: {
    },
}
</script>

<style scoped>
</style>
