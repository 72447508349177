<template>
    <div class="text-center" style="width: 100%">
        <span class="gray-footer2 paperFooter" style=" font-size: 16.1px;" @click="jump2Homepage" >BankInvoice Paper</span>
    </div>
</template>

<script>
import isMobile from '@/mixins/device'

export default {
    name: 'BankInvoicePaperFooter',
    components: {
    },
    data: function(){
        return {
            isMobile,
        }
    },
    computed: {
    },
    methods: {
        jump2Homepage() {
            let jump = !isMobile.isMobile()
            if(!jump) {
                jump = window.confirm(this.$i18n.tc("inputForm.jump2Homepage"))
            }

            if(jump) {
                const link = process.env.VUE_APP_HOMEPAGE
                window.open(link, '_blank')
            }

        }
    }
}
</script>

<style scoped>
</style>
