<template>
    <div class="p-field  p-col no_padding">
        <template v-for="(x, index) in LabelList">
            <template v-if="item.fix">
                <div v-if="x.label" class="p-field-radiobutton p-col p-nogutter no_padding p-fluid"  v-bind:class="{no_margin_bottom: (index == item.LabelList.filter(x => x.label).length - 1)}">
                    <Checkbox
                        :value="x.value" 
                        v-model="radioBox" 
                        @change="updateValue"
                    />
                    <label> {{x.label}} </label>
                </div>
            </template>
            <template v-else>
                <template v-if="edit">
                    <div v-if="x.label" class="p-field-radiobutton p-col p-nogutter no_padding p-fluid"  v-bind:class="{no_margin_bottom: (index == item.LabelList.filter(x => x.label).length - 1)}">
                        <Checkbox
                            :value="x.value" 
                            v-model="radioBox" 
                            @change="updateValue"
                        />
                        <label> {{x.label}} </label>
                    </div>
                </template>
                <template v-else>
                    <div  v-if="x.label" class="p-field-radiobutton p-col p-nogutter no_padding p-fluid" v-bind:class="{no_margin_bottom: (index == item.LabelList.filter(x => x.label).length - 1)}" >
                        <Checkbox 
                            :value="x.value"
                            :disabled="true"
                            v-model="radioBox" 
                        />
                        <label> {{x.label}} </label>
                    </div>
                </template>
            </template>
        </template>
        <div v-if="radioBox.findIndex(x => x == 'other') != -1" class="p-col-12 no_padding_side" >
            <span class="align-item-center p-col  p-fluid p-float-label no_padding " >
                <InputText
                    v-bind:class="{'fill_textfield': LabelList.find(x => x.value == 'other').otherValue }"
                    class="p-col textfield clear_disabled" type="text" v-model="LabelList.find(x => x.value == 'other').otherValue"   
                     :disabled ="!(edit || item.fix)"/>
                        <label style="">{{ item.Placeholder }}</label>
                    </span>
        </div>
    </div>
</template>

<script>
import Checkbox from 'primevue/checkbox'
import store from '@/store'
import InputText from 'primevue/inputtext';



export default {
    name: 'checkBoxGroupField',
    props: ['item', 'Id', 'edit'],
    emits: ["setDataInItem", "clearRequireField", "autoSaveData"],
    components: {
        Checkbox,
        InputText,
    },
    data() {
        return {
            updateVal: [],
        }
    },
    computed: {
        LabelList: {
            get() {
                const LabelList = this.item.LabelList ? this.item.LabelList : [{}]
                if (this.item.AddOtherInSelectionField && LabelList.findIndex(x => x.value == "other") == -1) {
                    LabelList.push({
                        label: this.$i18n.tc('xtype.checkBoxGroupField.otherLabel'),
                        value: "other",
                        otherValue: "",
                    })
                }

                return LabelList
            }
        },
        radioBox: {
            get: function() {
                const list = store.getters['inputForm/getActiveFormValues']
                if(list && list.length) {
                    const obj = list.find(x => x._id == this.item._id)
                    if(obj) {
                        return obj.ValueList ? obj.ValueList : []
                    }
                }
                return this.updateVal
            },
            set: function(value) {
                this.updateVal = value
                const data = {
                    Id: this.Id,
                    Xtype: "checkBoxGroupField",
                    ComponentId: this.item.ComponentId,
                    ItemId: this.item.ItemId,
                    LabelList: this.item.LabelList,
                    ValueList: value,
                    fix: this.item.fix,
                    Required: this.item.Required,
                    Placeholder: this.item.Placeholder,
                    _id: this.item._id,
                }
                this.$emit("setDataInItem", data)
            }
        }
    },
    methods: {
        updateValue: function(e) {
            this.$emit("clearRequireField", Object.keys(this.radioBox).length !== 0)
            this.$emit('autoSaveData', {
                Id: this.Id,
                Xtype: "checkBoxGroupField",
                ComponentId: this.item.ComponentId,
                ItemId: this.item.ItemId,
                ValueList: this.radioBox,
                _id: this.item._id,
            })
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>


