<template>
    <div class="p-field p-col  no_padding">
        <template v-for="(x, index) in LabelList">
            <template v-if="item.fix">
                <div v-if="x.label" class="p-field-radiobutton p-col p-nogutter no_padding p-fluid" v-bind:class="{no_margin_bottom: (index == item.LabelList.filter(x => x.label).length - 1)}" >
                    <RadioButton
                        :value="x.value" 
                        v-model="radioBox" 
                        :clearable="true"
                    />
                        <!-- @change="updateValue" -->
                    <label> {{x.label}} </label>
                </div>
            </template>
            <template v-else>
                <template v-if="edit">
                    <div v-if="x.label" class="p-field-radiobutton p-col p-nogutter no_padding p-fluid"  v-bind:class="{no_margin_bottom: (index == item.LabelList.filter(x => x.label).length - 1)}">
                        <RadioButton
                            :value="x.value" 
                            v-model="radioBox" 
                            :clearable="true"
                        />
                        <label> {{x.label}} </label>
                    </div>
                </template>
                <template v-else>
                    <div v-if="x.label" class="p-field-radiobutton p-col p-nogutter no_padding p-fluid"  v-bind:class="{no_margin_bottom: (index == item.LabelList.filter(x => x.label).length - 1)}">
                        <RadioButton 
                            :value="x.value"
                            :disabled="true"
                            v-model="radioBox" 
                        />
                        <label> {{x.label}} </label>
                    </div>
                </template>
            </template>
        </template>
        <div v-if="radioBox == 'other'" class="p-col-12 no_padding no_padding_bottom" >
            <!-- <InputText type="text" :placeholder="item.Placeholder" v-model="LabelList.find(x => x.value == 'other').otherValue" autoFocus  class="p-col-12 clear_disabled" :disabled ="!(edit || item.fix)" /> -->
            <span class="align-item-center p-col  p-fluid p-float-label no_padding " >
                <InputText
                    v-bind:class="{'fill_textfield': LabelList.find(x => x.value == 'other').otherValue }"
                    class="p-col textfield clear_disabled" type="text" v-model="LabelList.find(x => x.value == 'other').otherValue"   
                     :disabled ="!(edit || item.fix)"/>
                        <label style="">{{ item.Placeholder }}</label>
                    </span>
        </div>
    </div>
</template>

<script>
import InputText from 'primevue/inputtext';
import RadioButton from 'primevue/radiobutton';
import store from '@/store'



export default {
    name: 'radioBoxGroupField',
    props: ['item', 'Id', 'edit', 'required'],
    emits: ["setDataInItem", "clearRequireField", "autoSaveData"],
    components: {
        InputText,
        RadioButton,
    },
    data() {
        return {
            updateVal: null,
        }
    },
    computed: {
        LabelList: {
            get() {
                const LabelList = this.item.LabelList ? this.item.LabelList : [{}]
                if (this.item.AddOtherInSelectionField && LabelList.findIndex(x => x.value == "other") == -1) {
                    LabelList.push({
                        label: this.$i18n.tc('xtype.radioBoxGroupField.otherLabel'),
                        value: "other",
                        otherValue: "",
                    })
                }

                return LabelList
            }
        },
        radioBox: {
            get: function() {
                const list = store.getters['inputForm/getActiveFormValues']
                if(list && list.length) {
                    const obj = list.find(x => x._id == this.item._id)
                    if(obj) {
                        return obj.Value
                    }
                }
                return this.updateVal
            },
            set: function(value) {
                // this.updateVal = value
                const data = {
                    Id: this.Id,
                    Xtype: "radioBoxGroupField",
                    ComponentId: this.item.ComponentId,
                    ItemId: this.item.ItemId,
                    LabelList: this.item.LabelList,
                    Value: value,
                    _id: this.item._id,
                    fix: this.item.fix,
                    Required: this.item.Required,
                    Placeholder: this.item.Placeholder,
                }
                this.$emit("setDataInItem", data)
                this.$emit('autoSaveData', {
                    Id: this.Id,
                    Xtype: "radioBoxGroupField",
                    ComponentId: this.item.ComponentId,
                    ItemId: this.item.ItemId,
                    Value: this.radioBox,
                    _id: this.item._id,
                })
                this.$emit("clearRequireField", Boolean(this.radioBox))
            }
        }
    },
    methods: {
        updateValue: function(e) {
            this.$emit("clearRequireField", Boolean(this.radioBox))
            this.$emit('autoSaveData', {
                Id: this.Id,
                Xtype: "radioBoxGroupField",
                ComponentId: this.item.ComponentId,
                ItemId: this.item.ItemId,
                Value: this.radioBox,
                _id: this.item._id,
            })
        },
        onRadioButtonClick: function(e) {
            alert('input')
            if (!this.radioBox) return
            this.radioBox = null
            // this.$emit('autoSaveData', {
            //     Id: this.Id,
            //     Xtype: "radioBoxGroupField",
            //     ComponentId: this.item.ComponentId,
            //     ItemId: this.item.ItemId,
            //     Value: null,
            //     _id: this.item._id,
            // })
            // this.$emit("clearRequireField", String(!Boolean(null)))
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

