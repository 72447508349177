<template>
    <div style="margin-top: 12px;">
        <div class="text-center" v-if="publicFormatType == 'form'">
            <span class="gray-footer1" >{{$t('inputViewFooter.cantSendAmend')}}</span>
        </div>
        <div class="text-center" style="width: 100%; margin-bottom: 10px;">
            <span class="gray-footer1" v-if="AuthorName">{{$t('inputViewFooter.author', {Author: AuthorName})}}</span>
            <span class="gray-footer1" v-else >{{$t('inputViewFooter.text1')}}</span>
        </div>
        <BankInvoicePaperFooter />
    </div>
</template>

<script>
import BankInvoicePaperFooter from '@/components/BankInvoicePaperFooter';

export default {
    name: 'InputViewFooter',
    props: ["AuthorName", "publicFormatType"],
    components: {
        BankInvoicePaperFooter,
    },
    data: function(){
        return {
        }
    },
    computed: {
    },
    methods: {
    }
}
</script>

<style scoped>
</style>
