<template>
    <template v-if="item.fix">
        <div class=" p-col p-nogutter no_padding p-grid ">
            <div class="p-col-fixed  align-item-center">
                <Button
                    icon="pi pi-info-circle" class="p-button-rounded p-button-secondary p-button-text"
                    v-tooltip.focus="$t('xtype.fileField.enable')"
                    style="height: 2rem; width: 1.22rem;align-self: center;"
                />

                <Button class="p-button-outlined p-button-info bg-white-button"  @click="clearFileButtonClick" v-if="inputValueComputed" >{{$t('xtype.fileField.clear')}}</Button>
                <Button class="p-button-outlined p-button-info bg-white-button"  @click="uploadFileButtonClick" v-else >{{$t('xtype.fileField.label')}}</Button>
            </div>

            <label class="p-col text-ellipsis" style="padding: 9px; align-self: center;" > {{ inputValueComputed  }} </label>
            <input type="file" style="display: none;" ref="uploadButton" @change="changeUploadFile" />
        </div>
    </template>

    <template v-else>
        <div v-if="edit" class=" p-col p-nogutter no_padding p-grid width-100  align-item-center">
            <div class="p-col-fixed  align-item-center">
                <Button
                    icon="pi pi-info-circle"
                    class="p-button-rounded p-button-secondary p-button-text"
                    v-tooltip.focus="$t('xtype.fileField.enable')"
                    style="height: 2rem; width: 1.22rem;"
                    />
                <Button class="p-button-outlined p-button-info bg-white-button"  @click="clearFileButtonClick" v-if="inputValueComputed" >{{$t('xtype.fileField.clear')}}</Button>
                <Button class="p-button-outlined p-button-info bg-white-button"  @click="uploadFileButtonClick" v-else >{{$t('xtype.fileField.label')}}</Button>
            </div>
            <div class="p-col text-ellipsis" style="width: 90px;">
                <span class="p-col-fixed link" style="padding: 9px; align-self: center;" v-if="inputValueComputed" @click="download"> {{ inputValueComputed  }} </span>
                <span class="p-col-fixed" style="padding: 9px; align-self: center; color:#6c757d" v-else> {{ item.Placeholder }} </span>
            </div>
            <input type="file" style="display: none;" ref="uploadButton" @change="changeUploadFile" />
        </div>

        <div v-else class=" p-col p-nogutter no_padding p-grid ">
            <div class="p-col-fixed">
                <Button
                    icon="pi pi-info-circle" class="p-button-rounded p-button-secondary p-button-text"
                    v-tooltip.focus="$t('xtype.fileField.enable')"
                    style="height: 2rem; width: 1.22rem;align-self: center"
                />
            </div>
            <div class="p-col text-ellipsis" style="width: 100px;">
                <label class="text-ellipsis link" style="white-space: nowrap !important; padding: 9px;" @click="download"> {{ inputValueComputed }} </label>
            </div>
        </div>
    </template>
</template>

<script>
import http from '../../mixins/api'
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import { uuid } from "vue-uuid";
import helper from '@/mixins/helper';
import downloadFunc from '@/mixins/download';
import store from '@/store'
import Tooltip from 'primevue/tooltip'


export default {
    name: 'textField',
    props: ['item', 'edit', 'Id', 'required'],
    emits: ["setDataInItem", "clearRequireField"],
    components: {
        InputText,
        Button,
    },
    created() {
    },
    computed: {
        inputValueComputed: {
            get() {
                console.log(this.item)
                const list = store.getters['inputForm/getActiveFormValues']
                if(list && list.length) {
                    const obj = list.find(x => x._id == this.item._id)
                    if(obj && obj.File) {
                        return obj.File.FileName ? obj.File.FileName : ""
                    }
                }
                return "";
            },
        }
    },
    data() {
        return {
            fixInputText: "",
            fixValue: this.item.Value,
            allowField: null,
            display: false,
            // labelValue: null,
            // inputValue: null,
        }
    },
    methods: {
        input: function(e) {
            const data = {
                Id: this.Id,
                Xtype: "textField",
                ComponentId: this.item.ComponentId,
                ItemId: this.item.ItemId,
                Label: this.item.Label,
                Value: e.target.value,
                _id: this.item._id,
                Required: this.item.Required,
            }

            this.$emit("setDataInItem", data)
        },

        confirmFieldButtonClick: function() {
            const data = {
                Id: this.Id,
                Xtype: "textField",
                ComponentId: this.item.ComponentId,
                ItemId: this.item.ItemId,
                Label: this.item.Label,
                Value: this.fixValue,
                _id: this.item._id,
                Required: this.item.Required,
            }

            this.$emit("setDataInItem", data)
        },

        // editFieldButtonClick: function() {
        //     // this.display=true
        //     this.$emit('changeEditStatus:fix', true)
        //     // this.$emit('changeEditStatus:index')
        // },
        fixValueFunc: function(e) {
            // this.fixValue = e.target.value
            const file = e.target.files[0]
            const FileName = file.name
            if(helper.checkExt(FileName)) {
                this.fixInputText = FileName
                const data = {
                    // Value: this.fixValue,
                    File: file,
                    _id: this.item._id,
                    Xtype: "fileField",
                    ComponentId: this.item.ComponentId,
                    ItemId: this.item.ItemId,
                }
                store.commit('inputForm/fixingDataInItem', data)
            } else {
                this.$toast.add({severity:'error', summary: this.$i18n.tc("xtype.fileField.invalidFile"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
            }
        },

        closeDialog: function(e){ 
            this.display = false
        },

        uploadFileButtonClick: function(e) {
            this.$refs.uploadButton.click();
        },

        clearFileButtonClick: function(e) {
            this.$emit("setDataInItem", {
                _id: this.item._id,
                Xtype: "fileField",
                ComponentId: this.item.ComponentId,
                ItemId: this.item.ItemId,
                File: {},
                fix: this.item.fix,
            })
            this.$emit("clearRequireField", false)
        },

        changeUploadFile: function(e) {
            const file = e.target.files[0]
            const FileName = file.name
            if(!helper.checkFileNameLen(FileName)) {
                this.$toast.add({severity:'error', summary: this.$i18n.tc("xtype.fileField.tooLongFile", {LEN: this.$constants.maxFileNameLength}), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
                return;
            }
            if(!helper.checkExt(FileName)) {
                this.$toast.add({severity:'error', summary: this.$i18n.tc("xtype.fileField.invalidFile"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
                return;
            }

            this.$emit("setDataInItem", {
                _id: this.item._id,
                Xtype: "fileField",
                ComponentId: this.item.ComponentId,
                ItemId: this.item.ItemId,
                File: file,
                fix: this.item.fix,
                Required: this.item.Required,
            })
            this.$emit("clearRequireField", true)
        },

        async download() {
            let file = {}
            const list = store.getters['inputForm/getActiveFormValues']
            if(list && list.length) {
                const obj = list.find(x => x._id == this.item._id)
                const files = this.$store.getters['inputForm/getFiles']
                const file = files.find(f => f._uuid == obj._uuid)
                if(file) {
                    const Name = file.name
                    const fileReader = new FileReader()
                    fileReader.onload = async function() {
                        await downloadFunc.download(Name, fileReader.result, false)
                    }
                    fileReader.readAsDataURL(file)
                }
            }
        }

    },
    directives: {
        'tooltip': Tooltip
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
