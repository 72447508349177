<template>
    <template v-if="item.fix"> <span class="align-item-center p-col p-nogutter no_padding p-fluid p-float-label">
            <Calendar class="" v-bind:class="{required_field: required == 'true' && !datefieldModel}" v-model="datefieldModel"
                :value="inputValueComputed"
                @date-select="fixValueFunc"
                @clear-click="fixValueClearFunc"
                :dateFormat="dateFormat"
                showIcon="true"
                hourFormat="24"
                icon="pi pi-clock"
                showTime="true"
                showSaveButton="true"
                :touchUI="isMobile.isMobile()"
                showButtonBar="true"
            />
            <label style="left: 44px;">{{ item.Placeholder }}</label>
        </span>
    </template>
    <template v-else>
        <span class="align-item-center p-col p-md p-nogutter no_padding p-fluid p-float-label" v-if="edit">
            <Calendar 
                class="" 
                v-bind:class="{required_field: required == 'true' && !datefieldModel}" 
                v-model="datefieldModel" :key="item._id"
                @date-select="input"
                @clear-click="input"
                :dateFormat="dateFormat"
                hourFormat="24"
                showIcon="true"
                icon="pi pi-clock"
                showTime="true"
                showButtonBar="true"
                showSaveButton="true"
                :touchUI="isMobile.isMobile()"
            />
            <label style="left: 44px;">{{ item.Placeholder }}</label>
        </span>
        <span v-else class="align-item-center p-col p-nogutter no_padding p-fluid p-float-label" :key="item._id">
            <!-- <span style="">{{ $filters.formatDate(datefieldModel) }}</span> -->
            <!-- <label style="top: -0.05rem;">{{ item.Placeholder }}</label> -->
            <Calendar 
                class="clear_disabled"
                v-model="datefieldModel" :value="inputValueComputed" :key="item._id"
                :dateFormat="dateFormat"
                showIcon="true"
                showTime="true"
                showSaveButton="true"
                hourFormat="24"
                icon="pi pi-clock"
                :disabled="true"
            />
            <label style="left: 44px;">{{ item.Placeholder }}</label>
        </span>
    </template>
</template>

<script>
import Calendar from 'primevue/calendar';
import isMobile from '@/mixins/device'


export default {
    name: 'datetimeField',
    props: ['item', 'edit', 'Id', 'required'],
    emits: ["setDataInItem", "clearRequireField", "autoSaveData"],
    components: {
        Calendar,
    },
    computed: {
        dateFormat: {
            get() {
                const userInfo = this.$store.getters["auth/getUserInfo"];
                return (userInfo && userInfo.dateformat) ? userInfo.dateformat.toLowerCase().replace("yyyy", "yy") : "yy/mm/dd";
            }
        },
        inputValueComputed: {
            get() {
                const list = this.$store.getters['inputForm/getActiveFormValues']
                if(list && list.length) {
                    const obj = list.find(x => x._id == this.item._id)
                    if(obj) {
                        this.datefieldModel = obj.DateValue
                        return obj.DateValue
                    }
                }
                return "";
            },
        }
    },
    data() {
        return {
            fixValue: this.item.DateValue,
            allowField: null,
            display: false,
            datefieldModel: this.item.DateValue,
            fixTextfieldModel: null,
            isMobile,
            // labelValue: null,
            // inputValue: null,
        }
    },
    methods: {
        input: function(e) {
            const data = {
                Id: this.Id,
                Xtype: "datetimeField",
                ComponentId: this.item.ComponentId,
                ItemId: this.item.ItemId,
                DateValue: this.datefieldModel,
                _id: this.item._id,
                fix: this.item.fix,
                Required: this.item.Required,
            }

            this.$store.commit('inputForm/setEditindDataList', data)
            this.$emit('autoSaveData', {
                Id: this.Id,
                Xtype: "datetimeField",
                ComponentId: this.item.ComponentId,
                ItemId: this.item.ItemId,
                DateValue: this.datefieldModel,
                _id: this.item._id,
            })
            this.$emit("clearRequireField", Boolean(this.datefieldModel))
            // this.$emit("setDataInItem", data)
        },

        confirmFieldButtonClick: function() {
            const data = {
                Id: this.Id,
                Xtype: "datetimeField",
                ComponentId: this.item.ComponentId,
                ItemId: this.item.ItemId,
                DateValue: this.fixValue,
                _id: this.item._id,
                fix: this.item.fix,
                Required: this.item.Required,
            }

            this.$emit("setDataInItem", data)
        },

        fixValueFunc: function(e) {
            this.fixValue = this.datefieldModel
            const data = {
                DateValue: this.fixValue,
                _id: this.item._id,
            }
            this.$store.commit('inputForm/fixingDataInItem', data)
            this.$emit("clearRequireField", Boolean(this.fixValue))
        },

        fixValueClearFunc: function(e) {
            this.fixValue = this.datefieldModel
            const data = {
                DateValue: this.fixValue,
                _id: this.item._id,
            }
            this.$store.commit('inputForm/fixingDataInItem', data)
            this.$emit("clearRequireField", Boolean(this.fixValue))
        },

        closeDialog: function(e){ 
            this.display = false
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>




