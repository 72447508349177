<template>
<label class="p-col-fixed" style="width:40px">{{ item.Label }}</label>
</template>

<script>
import http from '../../mixins/api'
import store from '@/store'



export default {
    name: 'simpleText',
    props: ['item'],
    components: {
    },
    created() {
    },
    data() {
        return {
        }
    },
    methods: {
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>



