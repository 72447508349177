<template>
    <template v-if="item.fix">
        <span class="align-item-center p-col p-d-flex no_padding p-nogutter p-fluid p-inputgroup" >
            <span class="p-col p-nogutter no_padding p-fluid p-float-label" >
                <InputText type="text" 
                class="textfield" v-bind:class="{required_field: required == 'true' && !inputtingValue, 'p-filled': inputtingValue}" 
                :value="inputtingValue"  @input="fixValueFunc" 
                @blur="fixValueFunc"
                style="font-size: 14px;"
                ref="fixInputText" />
                <label>{{ item.Placeholder }}</label>
            </span>
            <span v-if="item.Unit" style="width: 80px;" class=" p-inputgroup-addon filledUnit">{{item.Unit}}</span>
        </span>
    </template>
    <template v-else>
        <span class="align-item-center p-col p-d-flex no_padding p-nogutter p-fluid p-inputgroup" v-if="edit" >
            <span class="p-col p-nogutter no_padding p-fluid p-float-label" >
                <InputText type="text"
                    class="textfield" 
                    :class="{required_field: required == 'true' && !(inputtingValue), 'p-filled': inputtingValue}"
                    :modelValue="inputtingValue"
                    :key="item._id"
                    @input="input"
                    style="font-size: 14px;"
                    @keypress.prevent.enter.exact="enable_enter"
                    @keyup.prevent.enter.exact="enter"

                    v-on:keyup.enter="enter"
                    @change="change"
                />
                <label >{{ item.Placeholder }}</label>
            </span>
            <span v-if="item.Unit" style="width: 80px;" class=" p-inputgroup-addon filledUnit">{{item.Unit}}</span>
        </span>

        <span v-else class="align-item-center p-col p-d-flex no_padding p-nogutter  p-inputgroup" >
            <span class="p-col p-nogutter no_padding p-fluid p-float-label" >
                <InputText class="textfield p-filled clear_disabled" style="font-size: 14px;" :value="inputValueComputed"  readOnly disabled />
                <label style="">{{ item.Placeholder }}</label>
            </span>
            <span v-if="item.Unit" style="width: 80px;" class=" p-inputgroup-addon filledUnit">{{item.Unit}}</span>
        </span>

        <!-- <span class="align-item-center p-col  p-nogutter no_padding p-fluid p-float-label" v-if="edit"> -->
        <!--     <InputText type="text" -->
        <!--         class="textfield"  -->
        <!--         :class="{required_field: required == 'true' &#38;&#38; !(inputtingValue), 'p-filled': inputtingValue}" -->
        <!--         :modelValue="inputtingValue" -->
        <!--         :key="item._id" -->
        <!--         @input="input" -->
        <!--         v-on:keyup.enter="enter" -->
        <!--         @change="change" -->
        <!--     /> -->
        <!--     <label >{{ item.Placeholder }}</label> -->
        <!-- </span> -->
        <!--  -->
        <!--  -->
        <!--  -->
        <!--  -->
        <!-- <span v-else class="align-item-center p-col p-nogutter no_padding p-fluid p-float-label" > -->
        <!--     <InputText class="textfield p-filled clear_disabled"  :value="inputValueComputed" style="" readOnly disabled /> -->
        <!--     &#60;&#33;&#45;&#45; <span style="" :class="{'p-filled': inputValueComputed}" >{{ inputValueComputed }}</span> &#45;&#45;&#62; -->
        <!--     <label style="">{{ item.Placeholder }}</label> -->
        <!-- </span> -->
    </template>
</template>

<script>
import InputText from 'primevue/inputtext';


export default {
    name: 'textField',
    props: ['item', 'edit', 'Id', 'required'],
    emits: ["setDataInItem", "clearRequireField", "autoSaveData"],
    components: {
        InputText,
    },
    watch: {
        'item.fix': function(fix){
            if(fix) {
                this.$nextTick(function() {this.$refs.fixInputText.$el.focus()});
                this.inputtingValue = this.inputValueComputed
            } else {
                this.inputtingValue = this.fixValue
            }
        }
    },
    computed: {
        inputValueComputed: {
            get() {
                const list = this.$store.getters['inputForm/getActiveFormValues']
                if(list && list.length) {
                    const obj = list.find(x => x._id == this.item._id)
                    if(obj) {
                        return obj.Value
                    }
                }
                return "";
            },
        }
    },
    data() {
        return {
            fixValue: this.item.Value,
            inputtingValue: this.item.Value,
            display: false,
            can_enter: false,

        }
    },
    created() {
    },
    methods: {

        blur: function(e) {
            const data = {
                Id: this.Id,
                Xtype: "textField",
                ComponentId: this.item.ComponentId,
                ItemId: this.item.ItemId,
                Label: this.item.Label,
                Placeholder: this.item.Placeholder,
                Value: this.inputtingValue,
                _id: this.item._id,
            }

            this.$store.commit('inputForm/setEditindDataList', data)
            // this.$emit("setDataInItem", data);
        },

        input: function(e) {
            this.inputtingValue = e.target.value
            // e.preventDefault()
            // e.target.nextElementSibling.focus()
        },

        enable_enter: function(e) {
            this.can_enter = true;
        },

        enter: function(e) {
            if (!this.can_enter) return;

            const elem = e.target
            const form = elem.form

            if(form) {
                const currentIndex = Array.from(form.elements).indexOf(elem);
                const nextIndex = currentIndex < form.elements.length - 1 ?  currentIndex + 1 : 0
                if(nextIndex > 0) {
                    form.elements.item(nextIndex).focus();
                }
            }
            this.can_enter = false;

        },

        change: function(e) {
            const data = {
                Id: this.Id,
                Xtype: "textField",
                ComponentId: this.item.ComponentId,
                ItemId: this.item.ItemId,
                Label: this.item.Label,
                Placeholder: this.item.Placeholder,
                Value: e.target.value,
                _id: this.item._id,
                Unit: this.item.Unit,
                Required: this.item.Required,
            }

            this.$store.commit('inputForm/setEditindDataList', data)
            this.$emit("clearRequireField", e.target.value.length !== 0)
            this.$emit('autoSaveData', {
                Id: this.Id,
                Xtype: "textField",
                ComponentId: this.item.ComponentId,
                ItemId: this.item.ItemId,
                Value: this.inputtingValue,
                _id: this.item._id,
            })
            // this.$emit("setDataInItem", data);
        },

        confirmFieldButtonClick: function() {
            const data = {
                Id: this.Id,
                Xtype: "textField",
                ComponentId: this.item.ComponentId,
                ItemId: this.item.ItemId,
                Label: this.item.Label,
                Placeholder: this.item.Placeholder,
                Value: this.fixValue,
                fix: this.item.fix,
                _id: this.item._id,
                Required: this.item.Required,
            }


            this.$emit("setDataInItem", data)
        },

        // editFieldButtonClick: function() {
        //     // this.display=true
        //     this.$emit('changeEditStatus:fix', true)
        //     // this.$emit('changeEditStatus:index')
        // },
        fixValueFunc: function(e) {
            this.fixValue = e.target.value
            const data = {
                Value: this.fixValue,
                _id: this.item._id,
            }
            this.$store.commit('inputForm/fixingDataInItem', data)
            this.$emit("clearRequireField", this.fixValue.length !== 0)
        },
        closeDialog: function(e){ 
            this.display = false
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

