<template>
    <template v-if="item.fix">
        <span class="align-item-center p-col p-nogutter no_padding p-fluid p-float-label">
            <MultiSelect class="p-col-12 text-left" :modelValue="inputtingValue" :options="LabelList" optionLabel="label" optionValue="value" display="chip"
                v-bind:class="{required_field: required == 'true' && !inputtingValue}" 
                :placeholder="$t('xtype.multiSelectField.placeHolder')"
                @change="fixValueFunc"
                ref="fixInputText"
            />
            <div v-if="inputtingValue && inputtingValue.find(x => x=='other')" class="p-col-12" >
                <!-- <InputText class="p-col" :placeholder="item.Placeholder" type="text" v-model="LabelList.find(x => x.value == 'other').otherValue"  autoFocus/> -->
            <span class="align-item-center p-col  p-fluid p-float-label no_padding " >
                <InputText
                    v-bind:class="{'fill_textfield': LabelList.find(x => x.value == 'other').otherValue }"
                    class="p-col textfield " type="text" v-model="LabelList.find(x => x.value == 'other').otherValue"   
                    autoFocus/>
                        <label style="">{{ item.Placeholder }}</label>
                    </span>
            </div>
        </span>
    </template>
    <template v-else>
        <span class="align-item-center p-col p-md p-nogutter no_padding p-fluid p-float-label" v-if="edit">
            <MultiSelect class="p-col-12 text-left" :modelValue="inputtingValue" :options="LabelList" optionLabel="label" optionValue="value" display="chip"
                v-bind:class="{required_field: required == 'true' && !inputtingValue}" 
                :placeholder="$t('xtype.multiSelectField.placeHolder')"
                @hide="onHideDropdown"
                @change="changeDropdownValue"
            />
            <div v-if="inputtingValue && inputtingValue.find(x => x=='other')" class="p-col-12" >
            <span class="align-item-center p-col  p-fluid p-float-label no_padding " >
                <InputText
                    v-bind:class="{'fill_textfield': LabelList.find(x => x.value == 'other').otherValue }"
                    class="p-col textfield " type="text" v-model="LabelList.find(x => x.value == 'other').otherValue"   
                    autoFocus/>
                        <label style="">{{ item.Placeholder }}</label>
                    </span>
            </div>
        </span>
        <span v-else class="align-item-center p-col p-nogutter no_padding p-fluid p-float-label" :key="item._id">
            <MultiSelect class="p-col-12 text-left clear_disabled" :modelValue="inputValueComputed" :options="LabelList" optionLabel="label" optionValue="value" display="chip"
                :placeholder="$t('xtype.multiSelectField.placeHolder')"
                readOnly 
                disabled
            />
            <div v-if="inputtingValue && inputValueComputed.find(x => x=='other')" class="p-col-12" >
            <span class="align-item-center p-col  p-fluid p-float-label  no_padding" >
                <InputText
                    v-bind:class="{'fill_textfield': LabelList.find(x => x.value == 'other').otherValue }"
                    class="p-col textfield  clear_disabled" type="text" v-model="LabelList.find(x => x.value == 'other').otherValue"   
                    disabled/>
                        <label style="">{{ item.Placeholder }}</label>
                    </span>
            </div>
        </span>
    </template>
</template>

<script>
import MultiSelect from 'primevue/multiselect';
import InputText from 'primevue/inputtext';
import store from '@/store'

export default {
    name: 'multiSelectField',
    props: ['item', 'edit', 'Id', 'required'],
    emits: ["setDataInItem", "clearRequireField", "autoSaveData"],
    components: {
        MultiSelect,
        InputText,
    },

    computed: {
        LabelList: {
            get() {
                const LabelList = this.item.LabelList ? this.item.LabelList : [{}]
                if (this.item.AddOtherInSelectionField && LabelList.findIndex(x => x.value == "other") == -1) {
                    LabelList.push({
                        label: this.$i18n.tc('xtype.multiSelectField.otherLabel'),
                        value: "other",
                        otherValue: "",
                    })
                }

                return LabelList
            }
        },
        inputValueComputed: {
            get() {
                const list = store.getters['inputForm/getActiveFormValues']
                if(list && list.length) {
                    const obj = list.find(x => x._id == this.item._id)
                    if(obj) {
                        return obj.ValueList
                    }
                }
                return [];
            },
        }
    },

    watch: {
        'item.fix': function(fix){
            if(fix) {
                this.$nextTick(function() {this.$refs.fixInputText.$el.focus()});
                this.inputtingValue = this.inputValueComputed
                this.fixValue = this.inputValueComputed
            } else {
                this.inputtingValue = this.fixValue
            }
        }
    },

    data() {
        return {
            fixValue: this.item.ValueList,
            display: false,
            inputtingValue: this.item.ValueList,
        }
    },
    methods: {
        confirmFieldButtonClick: function() {
            const data = {
                Id: this.Id,
                Xtype: "multiSelectField",
                ComponentId: this.item.ComponentId,
                ItemId: this.item.ItemId,
                Label: this.item.Label,
                Placeholder: this.item.Placeholder,
                ValueList: this.fixValue,
                LabelList: this.item.LabelList,
                fix: this.item.fix,
                _id: this.item._id,
                Required: this.item.Required,
            }

            this.$emit("setDataInItem", data)
        },

        fixValueFunc: function(e) {
            this.fixValue = e.value
            this.inputtingValue = e.value
            const data = {
                ValueList: this.fixValue,
                _id: this.item._id,
            }
            store.commit('inputForm/fixingDataInItem', data)
            this.$emit("clearRequireField", this.fixValue.length)
        },
        closeDialog: function(e){ 
            this.display = false
        },
        onHideDropdown: function(e) {
            // this.$emit("setDataInItem", data)
        },

        changeDropdownValue: function(e) {
            const value = e.value
            this.inputtingValue = value
            const data = {
                Id: this.Id,
                Xtype: "multiSelectField",
                ComponentId: this.item.ComponentId,
                ItemId: this.item.ItemId,
                Label: this.item.Label,
                Placeholder: this.item.Placeholder,
                ValueList: value,
                LabelList: this.item.LabelList,
                fix: this.item.fix,
                _id: this.item._id,
                Required: this.item.Required,
            }
            this.$store.commit('inputForm/setEditindDataList', data)
            this.$emit("clearRequireField", value.length)
            this.$emit('autoSaveData', {
                Id: this.Id,
                Xtype: "multiSelectField",
                ComponentId: this.item.ComponentId,
                ItemId: this.item.ItemId,
                ValueList: value,
                _id: this.item._id,
            })
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>



