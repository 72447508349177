<template>
    <Panel v-if="item.ComponentType == 'Editor'" class="inputFormPanel" v-bind:class="{'noheader_panel': !item.LabelTEXT, 'hasheader_panel': item.LabelTEXT, 'topComponentPanel': item.No == 1 }" >
        <template #header>
            <!-- <div class="panel-header-label" style="">  -->
            <!--     {{ item.Label }} -->
            <!-- </div> -->
            <LabelEditor class="input_form_label" style="flex: 1" :content="item.Label" :disabled="true"/>
        </template>
        <div class="p-grid" v-if="(item.items[0].Xtype == 'fullEditor' && item.items[0].Placeholder) || item.items[0].Xtype == 'fullEditor4Applicant'">
            <div style="" class="p-col no_margin align-item-end p-grid" >
                <slot></slot>
            </div>
        </div>
    </Panel>
    <Panel v-else class="inputFormPanel" v-bind:class="{noheader_panel: !item.LabelTEXT, 'hasheader_panel': item.LabelTEXT, 'topComponentPanel': item.No == 1 }" >
        <template #header>
            <!-- <div class="panel-header-label" style="word-break: break-word; text-align: left;">  -->
            <!--     {{ item.Label }} -->
            <!-- </div> -->
            <LabelEditor class="input_form_label" style="flex: 1" :content="item.Label" :disabled="true"/>
        </template>
        <div class="p-grid">
            <div class="p-col-12 p-md-4 p-lg-4 no_margin align-item-center p-as-center" v-if="item.Label1TEXT" v-bind:class="{'clear_required_field': item.Label1TEXT}">
                <div class=" p-d-flex">
                    <!-- <div class="max&#45;width: 60%; "> -->
                    <!--     <label class="" style="float: left; color: rgb(153, 153, 153);"> {{ item.Label1 }} </label> -->
                    <!-- </div> -->
                    <!-- <label class="itemLabel" style="max-width: 80%; word-break:break-word;" > -->
                    <!--     <span  > {{ item.Label1 }} </span> -->
                    <!-- </label> -->
                    <LabelEditor class=" no_padding input_form_label1" style="flex: 1; word-break:break-word;" :content="item.Label1" :disabled="true" />
                    <div class="p-col-fixed align-item-center" style="width: 65px; align-self: center; margin: 0 auto; margin-right: 0px !important;">
                        <template v-if="item.Required == 'true'">
                            <span class="no_padding tag_option_required text-right" style="">{{$t('inputForm.required')}}</span>
                        </template>
                        <template v-else-if="item.Required == 'false'">
                            <span class=" no_padding tag_option_notrequired text-right" style="">{{$t('inputForm.optional')}}</span>
                        </template>
                        <template v-else>
                            <span class="" style=""></span>
                        </template>
                    </div>
                </div>
            </div>
            <div style="max-width: 100%;" class="p-col no_margin align-item-end p-grid smart_no_padding " 
                v-bind:class="{
                    'p-col-12': !item.Label1TEXT, 
                    'required_field': (required == 'true' && edit) && item.items.findIndex(x => $constants.notFillRequireFieldArea.includes(x.Xtype)) == -1
                    
                }">
                <!-- <label class="p-col-12  p-d-block p-mx-auto no_padding_bottom input_form_label2_font"  v-bind:class="{'display_none': !item.Label2}" > {{ item.Label2 }} </label> -->
                <LabelEditor v-if="item.Label2TEXT" class="p-col-12 no_padding_bottom input_form_label2" :content="item.Label2" :disabled="true" />
                <slot></slot>
                <!-- <label class="p-col-12  p-d-block p-mx-auto no_padding_top no_padding_bottom input_form_label3_font" > {{ item.Label3 }} </label> -->
                <LabelEditor v-if="item.Label3TEXT" class="p-col-12 no_padding_top input_form_label3" :content="item.Label3" :disabled="true" />
            </div>
        </div>
    </Panel>
</template>

<script>
import InputText from 'primevue/inputtext';
import RadioButton from 'primevue/radiobutton';
import Checkbox from 'primevue/checkbox';
import Panel from 'primevue/panel';
import Textarea from 'primevue/textarea';
import LabelEditor from '@/components/templateFormParts/LabelEditor';


export default {
    name: 'formPanel',
    props: ['item', 'Id', 'edit', 'required'],
    components: {
        InputText,
        RadioButton,
        Checkbox,
        Panel,
        Textarea,
        LabelEditor,
    },
    data() {
        return {
            allowField: null,
        }
    },
    created() {
    },
    computed: {
    },
    methods: {
        inputHeader: function(e) {
            const data = {
                Id: this.Id,
                label: "Label",
                value: e.data
            }
            this.$emit('setProp', data)
        },
        inputLabel: function(e) {
            const data = {
                Id: this.Id,
                label: "Label1",
                value: e.data
            }
            this.$emit('setProp', data)
        },
        inputLabel2: function(e) {
            const data = {
                Id: this.Id,
                label: "Label2",
                value: e.data
            }
            this.$emit('setProp', data)
        },
        inputLabel3: function(e) {
            const data = {
                Id: this.Id,
                label: "Label3",
                value: e.data
            }
            this.$emit('setProp', data)
        },
        changeAllowField: function(e) {
            const data = {
                Id: this.Id,
                label: "Required",
                value: this.item.Required
            }
            this.$emit('setProp', data)
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
