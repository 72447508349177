<template>
    <template v-if="item.fix">
        <span class="align-item-center p-col p-nogutter no_padding p-fluid p-float-label">
            <Dropdown 
              class="p-col-12 text-left"
              :modelValue="fixValue" 
              :options="LabelList" 
              optionLabel="label" 
              optionValue="value"
              v-bind:class="{required_field: required == 'true' && !inputValueComputed}" 
              :placeholder="$t('xtype.dropDownField.placeHolder')"
              @change="fixValueFunc"
              :showClear="true"
              />
            <div v-if="fixValue=='other'" class="p-col-12" >
            <span class="align-item-center p-col  p-fluid p-float-label no_padding " >
            <InputText
                v-bind:class="{'fill_textfield': LabelList.find(x => x.value == 'other').otherValue }"
                class="p-col textfield " type="text" v-model="LabelList.find(x => x.value == 'other').otherValue"   
                autoFocus/>
                    <label style="">{{ item.Placeholder }}</label>
                </span>
            </div>
        </span>
    </template>
    <template v-else>
        <span class="align-item-center p-col p-md p-nogutter no_padding p-fluid p-float-label" v-if="edit">
            <Dropdown 
               class="p-col-12 text-left" 
               :modelValue="inputValueComputed" 
               :options="LabelList"
               optionLabel="label" 
               optionValue="value"
               v-bind:class="{required_field: required == 'true' && !inputValueComputed}" 
               :placeholder="$t('xtype.dropDownField.placeHolder')"
               @change="changeDropdownValue"
               :showClear="true"
               />
            <div v-if="inputValueComputed=='other'" class="p-col-12" >
            <span class="align-item-center p-col  p-fluid p-float-label no_padding " >
            <InputText
                v-bind:class="{'fill_textfield': LabelList.find(x => x.value == 'other').otherValue }"
                class="p-col textfield " type="text" v-model="LabelList.find(x => x.value == 'other').otherValue"   
                autoFocus/>
                    <label style="">{{ item.Placeholder }}</label>
                </span>
            </div>
        </span>
        <span v-else class="align-item-center p-col p-nogutter no_padding p-fluid p-float-label" :key="item._id">
            <Dropdown class="p-col-12 text-left clear_disabled" :modelValue="inputValueComputed" :options="LabelList" optionLabel="label" optionValue="value"
                readOnly 
                disabled
            />
            <div v-if="inputValueComputed=='other'" class="p-col-12" >
                <span 
                    class="align-item-center p-col  p-fluid p-float-label no_padding " >
                    <InputText
                            v-bind:class="{'fill_textfield': LabelList.find(x => x.value == 'other').otherValue }"
                            type="text" 
                            v-model="LabelList.find(x => x.value == 'other').otherValue"   
                            class="p-col clear_disabled"
                            disabled
                            />
                            <label style="">{{ item.Placeholder }}</label>
                </span>
            </div>
        </span>
    </template>
</template>

<script>
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';


export default {
    name: 'dropDownField',
    props: ['item', 'edit', 'Id', 'required'],
    emits: ["setDataInItem", "clearRequireField", "autoSaveData"],
    components: {
        Dropdown,
        InputText,
    },
    computed: {
        LabelList: {
            get() {
                const LabelList = this.item.LabelList
                if (this.item.AddOtherInSelectionField && LabelList.findIndex(x => x.value == "other") == -1) {
                    LabelList.push({
                        label: this.$i18n.tc('xtype.dropDownField.otherLabel'),
                        value: "other",
                        otherValue: "",
                    })
                }

                return LabelList
            }
        },
        inputValueComputed: {
            get() {
                const list = this.$store.getters['inputForm/getActiveFormValues']
                if(list && list.length) {
                    const obj = list.find(x => x._id == this.item._id)
                    if(obj) {
                        return obj.Value
                    }
                }
                return null;
            },
            set(value) {
                // console.log(value)
                // if(this.item.fix) {
                //     this.fixValue = value
                //     const data = {
                //         Value: this.fixValue,
                //         _id: this.item._id,
                //     }
                //     store.commit('inputForm/fixingDataInItem', data)
                //     this.$emit("clearRequireField", this.fixValue.length !== 0)
                // } else {
                //     const data = {
                //         Id: this.Id,
                //         Xtype: "dropDownField",
                //         ComponentId: this.item.ComponentId,
                //         ItemId: this.item.ItemId,
                //         Label: this.item.Label,
                //         Placeholder: this.item.Placeholder,
                //         Value: value,
                //         LabelList: this.item.LabelList,
                //         _id: this.item._id,
                //     }
                //     this.$emit("setDataInItem", data)
                // }
            },
        }
    },
    data() {
        return {
            fixValue: this.item.Value,
            allowField: null,
            display: false,
            textfieldModel: null,
            fixTextfieldModel: null,
            // labelValue: null,
            // inputValue: null,
        }
    },
    methods: {
        fixValueFunc: function(e) {
            this.fixValue = e.value
            const data = {
                Value: this.fixValue,
                _id: this.item._id,
            }
            this.$store.commit('inputForm/fixingDataInItem', data)
            this.$emit("clearRequireField", this.fixValue)
        },
        changeDropdownValue: function(e) {
            const value = e.value
            // this.inputValueComputed = value
            this.fixValue = value
            const data = {
                Id: this.Id,
                Xtype: "dropDownField",
                ComponentId: this.item.ComponentId,
                ItemId: this.item.ItemId,
                Label: this.item.Label,
                Placeholder: this.item.Placeholder,
                Value: value,
                LabelList: this.item.LabelList,
                Required: this.item.Required,
                fix: this.item.fix,
                _id: this.item._id,
            }
            this.$emit("setDataInItem", data)
            this.$emit("clearRequireField", value)

            this.$emit('autoSaveData', {
                Id: this.Id,
                Xtype: "dropDownField",
                ComponentId: this.item.ComponentId,
                ItemId: this.item.ItemId,
                Value: value,
                _id: this.item._id,
            })
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>


