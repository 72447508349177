<template>
    <template v-if="item.fix">
        <span class="align-item-center p-col p-nogutter no_padding p-fluid p-float-label float-nowrap-label">
            <Textarea type="text" class="textfield " v-bind:class="{required_field: required == 'true' && !inputtingValue, 'p-filled': inputtingValue}" :value="inputtingValue"  @input="fixValueFunc" ref="fixInputArea"  autoResize rows="5" />
            <label>{{ item.Placeholder }}</label>
        </span>
    </template>
    <template v-else>
        <span class="align-item-center p-col p-md p-nogutter no_padding p-fluid p-float-label float-nowrap-label" v-if="edit">
            <Textarea type="text"
                class="textfield" 
                v-bind:class="{required_field: required == 'true' && !inputtingValue, 'p-filled': inputtingValue}" 
                :value="inputtingValue"  :key="item._id"
                @input="input"
                @change="change" autoResize rows="5" />
            <label>{{ item.Placeholder }}</label>
        </span>
        <span v-else class="align-item-center p-col p-nogutter no_padding p-fluid p-float-label float-nowrap-label" :key="item._id">

            <!-- <p style="white-space: pre-wrap; text-align:left;word-break: break-word;"> {{ inputValueComputed }} </p> -->
            <!-- <label style="top: -0.05rem;">{{ item.Placeholder }}</label> -->
            <Textarea
                type="text"
                v-bind:class="{'p-filled': inputtingValue}" 
                :value="inputValueComputed"  
                :key="item._id"
                readOnly
                autoResize rows="5" 
                />
            <label >{{ item.Placeholder }}</label>
        </span>
    </template>
</template>

<script>
import Textarea from 'primevue/textarea';
import store from '@/store'


export default {
    name: 'textAreaField',
    props: ['item', 'edit', 'Id', 'required'],
    emits: ["setDataInItem", "clearRequireField", "autoSaveData"],
    components: {
        Textarea,
    },
    computed: {
        inputValueComputed: {
            get() {
                const list = store.getters['inputForm/getActiveFormValues']
                if(list && list.length) {
                    const obj = list.find(x => x._id == this.item._id)
                    if(obj) {
                        return obj.Value
                    }
                }
                return "";
            },
        }
    },
    data() {
        return {
            fixValue: this.item.Value,
            inputtingValue: this.item.Value,
            allowField: null,
            display: false,
            fixTextfieldModel: null,
            // labelValue: null,
            // inputValue: null,
        }
    },
    watch: {
        'item.fix': function(fix){
            if(fix) {
                this.$nextTick(function() {this.$refs.fixInputArea.$el.focus()});
                this.inputtingValue = this.inputValueComputed
            } else {
                this.inputtingValue = this.fixValue
            }
        }
    },
    methods: {
        input: function(e) {
            this.inputtingValue = e.target.value
        },

        change: function(e) {
            const data = {
                Id: this.Id,
                Xtype: "textAreaField",
                ComponentId: this.item.ComponentId,
                ItemId: this.item.ItemId,
                Label: this.item.Label,
                Placeholder: this.item.Placeholder,
                Value: e.target.value,
                _id: this.item._id,
                fix: this.item.fix,
                Required: this.item.Required,
            }

            this.$emit("setDataInItem", data)
            this.$emit('autoSaveData', {
                Id: this.Id,
                Xtype: "textAreaField",
                ComponentId: this.item.ComponentId,
                ItemId: this.item.ItemId,
                Value: e.target.value,
                _id: this.item._id,
            })
        },

        confirmFieldButtonClick: function() {
            const data = {
                Id: this.Id,
                Xtype: "textAreaField",
                ComponentId: this.item.ComponentId,
                ItemId: this.item.ItemId,
                Label: this.item.Label,
                Placeholder: this.item.Placeholder,
                Value: this.fixValue,
                _id: this.item._id,
                fix: this.item.fix,
                Required: this.item.Required,
            }

            this.$emit("setDataInItem", data)
        },

        // editFieldButtonClick: function() {
        //     // this.display=true
        //     this.$emit('changeEditStatus:fix', true)
        //     // this.$emit('changeEditStatus:index')
        // },
        fixValueFunc: function(e) {
            this.fixValue = e.target.value
            const data = {
                Value: this.fixValue,
                _id: this.item._id,
            }
            console.log(data)
            store.commit('inputForm/fixingDataInItem', data)
            this.$emit("clearRequireField", this.fixValue.length !== 0)
        },
        closeDialog: function(e){ 
            this.display = false
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>


