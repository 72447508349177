<template>
    <template v-if="edit">
        <fullEditorsEditor @setDataInItem="input($event)" :content="editorValue" :disabled="false" :isEditor4Applicant="true" />
    </template>
    <template v-else>
        <fullEditorsEditor @setDataInItem="input($event)" :content="editorValue" :disabled="(!item.fix)" :isEditor4Applicant="true" :applicantMode="true" />
    </template>
</template>

<script>
import fullEditorsEditor from '@/components/templateFormParts/fullEditorsEditor'


export default {
    name: 'fullEditor4Applicant',
    props: ['item', 'edit', 'Id', 'required'],
    emits: [''],
    components: {
        fullEditorsEditor
    },
    data() {
        return {
        }
    },
    watch: {
    },
    mounted() {
    },

    beforeUnmount() {
    },

    computed: {
        editorValue: {
            get() {
                const list = this.$store.getters['inputForm/getActiveFormValues']
                if(list && list.length) {
                    const obj = list.find(x => x._id == this.item._id)
                    if(obj) {
                        if(obj.Placeholder && !obj.Value) {
                            this.input({html: obj.Placeholder})
                        } else {
                            return obj.Value
                        }
                    }
                }
                return "";
            },
        }
    },
    methods: {
        input: function(e={}) {
            if (this.edit) {
                this.editContent(e)
            }else if(this.item.fix) {
                this.fixValueFunc(e.html)
            }
        },

        editContent(e) {
            const data = {
                Id: this.Id,
                Xtype: "fullEditor4Applicant",
                ComponentId: this.item.ComponentId,
                ItemId: this.item.ItemId,
                Value: e.html,
                _id: this.item._id,
                fix: this.item.fix,
            }

            this.$emit("setDataInItem", data);
            this.$emit('autoSaveData', {
                Id: this.Id,
                Xtype: "fullEditor4Applicant",
                ComponentId: this.item.ComponentId,
                ItemId: this.item.ItemId,
                Value: e.html,
                _id: this.item._id,
            })
        },

        fixValueFunc: function(value) {
            this.fixValue = value
            const data = {
                Value: this.fixValue,
                _id: this.item._id,
            }
            this.$store.commit('inputForm/fixingDataInItem', data)
        },
    },
    directives: {
    },
}
</script>

<style scoped>
</style>
